@import url('https://cdn.jsdelivr.net/npm/@fancyapps/ui@4.0/dist/fancybox.css');¨

* {
    box-sizing: border-box;
    box-sizing: inherit;
}
html, body {
    font-size: 62.5%;
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    background: #ece9e6;
    background: -webkit-linear-gradient(to right, #ece9e6, #ffffff);
    background: linear-gradient(to right, #ece9e6, #ffffff);
}
body {
    margin: 0;
}
#root {
    height: 100%;
}

/* PAGE BLOCKS */
.centered {
    display: inline-flex;
}
.section {
    font-size: 2.6em;
    vertical-align: top;
    color: #555;
    padding: 15px;
}

/* HEADER */
.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 215px;
    text-align: center;
    background: #232526;
    background: -webkit-linear-gradient(to right, #232526, #414345);
    background: linear-gradient(to right, #232526, #414345);
}
.header-container {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-align: left;
}
.header-logo {
    padding-right: 25px;
}
.header-logo > img {
    height: 100px;
    width: auto;
}

/* NAV */
.nav {
    padding: 40px;
    font-size: 2.8em;
}
.nav-link {
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
    margin-top: -5px;
}
.nav-link > img {
    height: 50px;
    width: auto;
}
.icon-portfolio > img {
    padding: 5px;
}
.icon-portfolio > img, .icon-about > img {
    height: 40px;
    width: auto;
}

/* GALLERY */
.portfolio-wrapper {
    padding-top: 215px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-flow: row wrap;
	    flex-flow: row wrap;
}
.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.gallery-image {
    box-sizing: border-box;
}
.gallery::after {
    content: "";
    flex: auto;
}
.fancybox__caption {
    color: white;
}
.fancybox__image {
    width: 100%;
    height: auto;
}
.fancybox__content {
    width: 100%;
    height: auto;
}

/* FRONTPAGE */
.frontpage-wrapper {
    box-sizing: border-box;
    padding-top: 215px;
    height: 100%;
}
.frontpage-left {
    vertical-align: top;
    height: 100%;
    background-image: url("../public/frontpage/20220423_001.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
}
.frontpage-right {
    vertical-align: top;
}
.frontpage-centered {
    max-width: 475px;
}
.frontpage-section {
    font-size: 2.6em;
    color: #555;
}



@media all and (min-width: 768px) {
    .frontpage-wrapper {
        display: flex;
    }
    .frontpage-left {
        width: 50%;
        max-width: 1000px;
    }
    .frontpage-right {
        width: 50%;
        flex-grow: 100;
        display: flex;
        flex-direction:column;
        justify-content: space-between;
        justify-content: center;
        align-items: center;
    }
    .frontpage-centered {
        padding: 0 100px 0 100px;
    }
    .gallery {
        width: 100%;
    }
    .gallery-image {
        width: auto;
        height: 250px;
        padding: 10px;
    }
}
@media all and (max-width: 768px) {
    .nav {
        padding-left: 0;
    }
    .frontpage-left {
        width: 100%;
        height: 50%;
    }
    .frontpage-right {
        box-sizing: border-box;
        width: 100%;
    }
    .frontpage-centered {
        padding: 35px;
    }
    .gallery {
        width: 100%;
    }
    .gallery-image {
        width: 100%;
        height: auto;
        padding: 0;
    }
}